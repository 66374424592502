<template>
  <div>
    <div class="header">
      <div class="session1">
        <el-input
          placeholder="请输入SolutionID进行检索"
          v-model="keyword"
          class="input-with-select searchInput"
          @change="searchByKeywords()"
        >
          <el-button class="searchbtn" slot="append" icon="el-icon-search" @click="searchByKeywords()"></el-button>
        </el-input>
      </div>
    </div>
    <div class="operation clearfix">
      <div style="position: relative" ref="customDialog" class="fr">
        <a class="downloadstyle bord" :href="downloadurl"><i class="fa fa-download fhd"></i></a>
      </div>
    </div>
    <div class="integratable">
      <el-table
        ref="singleTable"
        v-loading="isFetching"
        :highlight-current-row="true"
        :data="tableData"
        @sort-change="sortChange"
        style="width: 100%"
        row-key="pkid"
      >
        <el-table-column
          v-for="(item, index) in columns"
          :min-width="item.width"
          :key="index"
          :label="item.label"
          :prop="item.key"
          :sortable="item.sortable"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="item.key === 'status'">--</div>
            <div v-else-if="item.key === 'shopname'">
              <el-tooltip class="item" effect="dark" :content="scope.row.shopname" placement="top">
                <span>{{ scope.row.shopname.slice(0, 10) }}{{ scope.row.shopname.length > 10 ? '...' : '' }}</span>
              </el-tooltip>
            </div>
            <div v-else-if="item.key === 'toshop'">
              <span>{{ formatLabel(assignStatusList, scope.row.toShop) }}</span>
            </div>
            <div v-else-if="item.key === 'operation'">
              <el-button @click="restFirst(scope.row)" type="text" size="small">门店分配</el-button>
              <el-button @click="restSecond(scope.row)" type="text" size="small">设备管理</el-button>
            </div>
            <div v-else>{{ isEmpty(scope.row[item.key]) ? '--' : scope.row[item.key] }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flexend">
      <pagination
        :currentPage="pageOptions.pageNumber"
        :total="total"
        :pageSize="pageOptions.pageSize"
        @size="handleSizeChange"
        @current="handleCurrentChange"
      ></pagination>
    </div>
  </div>
</template>

<script>
import treemixin from 'src/mixins/tree'
import Pagination from 'src/components/Pagination'
import tmpColumn from 'src/mixins/templateColumn'
import { columns1 } from './common'
import { getProductList } from 'src/api/product'
import { storeHttp, formatLabel, generateTableData } from 'src/utils/common'
import { runningStatusList, assignStatusList } from 'src/utils/dict'
import { isEmpty } from 'src/utils/method'

const pathList = [
  {
    path: '/productManage/retailTraffic',
    value: 0,
  },
  {
    path: '/productManage/security',
    value: 4,
  },
]

export default {
  components: {
    Pagination,
  },
  mixins: [treemixin, tmpColumn],
  data() {
    const productType = pathList.find((item) => this.$route.path.startsWith(item.path))?.value

    return {
      columns: columns1,
      dataSource: [],
      tableData: [],

      prop: '',
      order: '',

      isFetching: true,
      pageOptions: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: 0,
      toshop: this.$route.query.toshop || '0',
      keyword: '',

      productType,
      runningStatusList,
      assignStatusList,
    }
  },
  watch: {
    pageOptions: {
      handler() {
        this.formatTableData()
      },
      deep: true,
    },
  },
  computed: {
    downloadurl() {
      return (
        storeHttp +
        '/api/solution_package/getProductList/download?keyword=' +
        this.keyword +
        '&toshop=' +
        this.toshop +
        '&orgcode=' +
        this.orgcode +
        '&productType=' +
        this.productType +
        '&status='
      )
    },
  },
  created() {
    this.overviewInit()
  },
  methods: {
    isEmpty,
    formatLabel,
    overviewInit() {
      this.fetchList()
    },
    async searchByKeywords() {
      await this.fetchList()
      this.pageOptions.pageNumber = 1
    },
    formatQueryData(data = {}) {
      return {
        ...data,
        productType: this.productType,
        toshop: parseInt(this.$route.query.toshop) || '0',
        keyword: this.keyword,
      }
    },
    formatTableData() {
      this.tableData = generateTableData(this.dataSource, { order: this.order, prop: this.prop }, this.pageOptions)
    },
    async fetchList() {
      this.isFetching = true
      const params = this.formatQueryData()

      try {
        const res = await getProductList(params)
        this.dataSource = res.data || []
        this.total = res.data?.length || 0
        this.formatTableData()
      } finally {
        this.isFetching = false
      }
    },
    handleSizeChange(val) {
      this.pageOptions.pageSize = val
    },
    handleCurrentChange(val) {
      this.pageOptions.pageNumber = val
    },
    sortChange(column) {
      const { prop, order } = column
      this.prop = prop
      this.order = order
      this.formatTableData()
    },
    restFirst(row) {
      let name = ''
      const currRouteName = this.$route.name
      if (currRouteName === 'retail-traffic-index') {
        name = 'retail-traffic-config'
      } else if (currRouteName === 'security-index') {
        name = 'security-config'
      }

      this.$router.push({
        name,
        query: {
          productId: row.productId,
          productType: row.productType,
          fromRoute: this.$route.name,
        },
      })
    },
    restSecond(row) {
      let name = ''
      const currRouteName = this.$route.name
      if (currRouteName === 'retail-traffic-index') {
        name = 'retail-traffic-device'
      } else if (currRouteName === 'security-index') {
        name = 'security-device'
      }

      this.$router.push({
        name,
        query: {
          productId: row.productId,
          shopName: row.shopName,
          cname: row.cname,
          shopId: row.shopId,
          status: row.status,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  padding: 20px;
  .item {
    display: flex;
    align-items: center;
  }
  .session1 {
    display: flex;
    align-items: center;
  }
}
.menu {
  display: flex;
  align-items: center;
}
.operation {
  padding-top: 20px;
  border-top: 1px solid #e6e6e6;
}
.el-button.el-button--default.primary {
  border: 1px solid #5c76cc;
  background-color: #5c76cc;
  color: #fff;
}
.menu .el-button + .el-button {
  margin-left: -4px;
}
.el-button.newbtn {
  width: 60px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  padding: 0;
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  border-radius: 2px;
  font-size: 12px;
}
.batchbtn {
  width: 80px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  padding: 0;
  background: #f4f5f7;
  border-radius: 2px;
  border-color: #d1d1d1;
  font-size: 12px;
  margin-left: 10px;
}
.el-select .el-input {
  width: 318px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.searchInput {
  width: 350px;
}

/* 按钮 */
.newbtn.el-button.is-plain:active {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  outline: 0;
}
.newbtn.el-button.is-active,
.newbtn.el-button.is-plain:active {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
}
.newbtn.el-button.is-plain:focus,
.newbtn.el-button.is-plain:hover {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  width: 60px;
  height: 30px;
}
.newbtn.el-button:active {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  outline: 0;
}
.newbtn.el-button:focus,
.newbtn.el-button:hover {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  width: 60px;
  height: 30px;
}

.batchbtn.el-button.is-plain:active {
  color: #333;
  background: #f4f5f7;
  outline: 0;
}
.batchbtn.el-button.is-active,
.batchbtn.el-button.is-plain:active {
  color: #333;
  background: #f4f5f7;
}
.batchbtn.el-button.is-plain:focus,
.batchbtn.el-button.is-plain:hover {
  color: #333;
  background: #f4f5f7;
  border-color: #d1d1d1;
  width: 80px;
  height: 30px;
}
.batchbtn.el-button:active {
  color: #333;
  background: #f4f5f7;
  border-color: #d1d1d1;
  outline: 0;
}
.batchbtn.el-button:focus,
.batchbtn.el-button:hover {
  color: #333;
  background: #f4f5f7;
  border-color: #d1d1d1;
  width: 80px;
  height: 30px;
}

.flexend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}
.popupStyle {
  width: 420px;
  position: absolute;
  top: 33px;
  left: -298px;
  background-color: #ffffff;
  border-radius: 2px;
  z-index: 99;
}
.chooseProp {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.trend-layout-block {
  position: relative;
  padding: 15px;
}
.trend-layout-block label input {
  margin-right: 7px;
}
.popupStyle label {
  width: 128px;
  margin-bottom: 15px;
}
.downloadstyle {
  width: 61px;
  height: 30px;
  position: relative;
  bottom: -1px;
  line-height: 27px;
  border-radius: 2px;
  text-align: center;
  color: #000;
  font-size: 14px;
  background-color: #f4f5f7;
  z-index: 1;
}
.add-indent label {
  width: 121px;
  height: 16px;
  overflow: hidden;
}

.el-select .el-input,
.el-select .el-input .el-input__inner,
.el-form-item__content .el-input .el-input__inner {
  width: 200px;
}
.el-select-dropdown.el-popper {
  min-width: 200px !important;
}
.addDialog .el-input__prefix,
.addDialog .el-input__suffix {
  top: 6px;
}

/* 导入摄像头 */
.userList div.le1 {
  font-size: 14px;
  color: #333;
  text-align: right;
  font-weight: bold;
  width: 90px;
  text-align: right;
}
.userList .elDiv {
  font-size: 12px;
  color: #333;
  margin-left: 20px;
}
.userList .elDiv > input {
  border: 1px solid #c9c9c9;
  border-radius: 2px;
  width: 210px;
  height: 30px;
  font-size: 12px;
  padding-left: 10px;
  margin-right: 10px;
}
.errorShow {
  font-size: 12px;
  color: #ff2600;
  letter-spacing: 0;
  line-height: 12px;
  padding-left: 110px;
}
.alertInfo {
  line-height: 30px;
  background: #f4f5f7;
  border-radius: 4px;
  opacity: 0.85;
  padding: 0 20px;
  color: #999;
}
.userList a {
  font-size: 12px;
  color: #5c76cc;
  cursor: pointer;
}

.importDialog .el-dialog__body {
  padding-bottom: 30px;
}

.loadbtn {
  height: 30px;
  line-height: 30px;
}

.el-input-group__append,
.el-input-group__prepend {
  padding: 0 4px;
}
.el-input-group > .el-input__inner {
  width: 350px;
}
.seqcheck {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 10px;
}
.pass {
  background-color: #52cc8f;
}
.nopass {
  background-color: #e65c5c;
}

/* 设置自定义列 */
.el-checkbox + .el-checkbox {
  margin-left: 0;
}
.el-checkbox .el-checkbox {
  margin-right: 30px;
}
.ag-custom-column label {
  width: 33.33%;
  margin-bottom: 14px;
}
.ag-custom-column {
  width: 410px;
  position: absolute;
  top: 42px;
  right: 20px;
  background-color: #ffffff;
  border-radius: 2px;
  z-index: 99;
}
.bor {
  border: 1px solid #d2d2d2;
}
.fts {
  font-size: 12px;
}
.ag-layout-block {
  position: relative;
  padding: 15px;
}
.ag-layout-block.hori {
  padding-top: 0;
  padding-bottom: 0;
}
label {
  display: inline-block;
  line-height: 1.42857;
  font-style: normal;
}
.ag-model-btn {
  font-family: 'Microsoft Yahei', Helvetica, sans-serif;
  min-width: 63px;
  height: 30px;
  padding: 0 10px;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 2px;
}
.ag-model-btn.blue {
  color: #fff;
  border: 1px solid #5c76cc;
  background-color: #5c76cc;
}
.ag-model-btn.gray {
  color: #333;
  background-color: #f4f5f7;
  border: 1px solid #d2d2d2;
}
.ag-model-btn.gray:active {
  border-color: #d2d2d2;
  background-color: #dddee0;
}
.ag-model-btn.gray:hover {
  border-color: #d2d2d2;
  background-color: #fff;
}
.ag-model-btn.blue:active {
  border-color: #5c76cc;
  background-color: #5c76cc;
}
.ag-model-btn.blue:hover {
  border-color: #5c76cc;
  background-color: #5c76cc;
}

.addDialog .el-input {
  width: 200px;
}

.el-input-group {
  width: 350px;
}

.el-input-group__append,
.el-input-group__prepend {
  padding: 0 4px;
}

.addDialog .shopBox span {
  top: 12px;
}

.addDialog .treeBox {
  left: 0;
}

.tree-disabled {
  cursor: not-allowed;
  color: #aca899;
}

.required .el-form-item__label:before {
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
}

.el-form-item.is-error .shop-name {
  border-color: #f56c6c;
}
.pswbox {
  position: relative;
  width: 200px;
}
.pswbox .el-input__inner {
  padding-right: 23px;
}
.password-icon {
  position: absolute;
  z-index: 100;
  top: 3px;
  right: 5px;
  font-size: 12px;
}
.upload > span {
  font-weight: bold;
  margin: 0 20px;
}
.el-dialog__footer {
  padding: 20px;
}
.assignDialog-footer > span {
  color: #5c76cc;
  cursor: pointer;
}
</style>