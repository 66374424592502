<template>
  <div class="app-container">
    <tab1 />
    <!-- <el-tabs v-model="activeTab" @tab-click="handleClick">
      <el-tab-pane lazy label="已分配" name="1">
        <tab1 />
      </el-tab-pane>
      <el-tab-pane lazy label="未分配" name="0">
        <tab2 />
      </el-tab-pane>
    </el-tabs> -->
  </div>
</template>

<script>
import Tab1 from './tab1.vue'
import Tab2 from './tab2.vue'

export default {
  components: { Tab1, Tab2 },
  data() {
    return {
      activeTab: this.$route.query.toshop || '1',
    }
  },
  created() {
    // this.$router.push({ query: { toshop: this.activeTab, ...this.$route.query } })
  },
  methods: {
    handleClick(tab) {
      this.activeTab = tab.name
      this.$router.push({ query: { ...this.$route.query, toshop: tab.name } })
    },
  },
}
</script>

<style lang="less" scoped>
.app-container {
  padding-top: 12px;
}
/deep/ .el-tabs__content {
  min-height: 80vh;
}
</style>