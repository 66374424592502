<template>
  <div>
    <div class="header query-wrap">
      <div class="query">
        <div class="menu item">
          <span class="font_14 label">运行状态：</span>
          <el-button
            v-for="item in runningStatusList"
            :key="item.label"
            :class="status == item.value ? 'primary' : ''"
            @click="handleSelect(item.value)"
          >
            {{ item.label }}
          </el-button>
        </div>
        <div class="item session1">
          <div class="shopBox ml20" ref="shopBox" @click="treeClick">
            <i>组织：</i>
            <input type="text" readonly :value="checkedName" />
            <span class="el-tree-node__expand-icon el-icon-caret-right expanded"></span>
          </div>
          <div class="treeBox" ref="treeBox" v-show="treeIsShow">
            <el-input
              placeholder="请输入组织名称搜索"
              v-model="searchOrg"
              @change="searchOrg"
              class="input-with-select"
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
            <el-tree
              node-key="orgcode"
              :data="shopsTree"
              :props="defaultProps"
              ref="tree"
              :default-expanded-keys="[orgcode]"
              :filter-node-method="filterNode"
              :expand-on-click-node="false"
              :highlight-current="true"
              @node-click="handleNodeClick"
            ></el-tree>
            <div class="dialogBtn pt20 mb20 borderTop">
              <button @click="getOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
              <button @click="hideAll" type="button" class="ag-model-btn gray fts">取消</button>
            </div>
          </div>
        </div>
        <div class="item">
          <el-input
            placeholder="请输入SolutionID进行检索"
            v-model="keyword"
            class="input-with-select searchInput"
            @change="searchByKeywords()"
          >
            <el-button class="searchbtn" slot="append" icon="el-icon-search" @click="searchByKeywords()"></el-button>
          </el-input>
        </div>
      </div>
    </div>
    <div class="operation">
      <div class="left">
        <el-button type="primary" @click="toGenerateSolution">新建解决方案</el-button>
      </div>
      <div style="position: relative" ref="customDialog">
        <a class="downloadstyle bord" :href="downloadurl"><i class="fa fa-download fhd"></i></a>
        <!-- <button class="ag-grey-button bord ml10" v-on:click="setTrue()">
          <i class="fa fa-gear thd"></i>
        </button> -->
      </div>
    </div>
    <div class="integratable">
      <el-table
        ref="singleTable"
        style="width: 100%"
        v-loading="isFetching"
        :highlight-current-row="true"
        :data="tableData"
        @sort-change="sortChange"
      >
        <el-table-column
          v-for="(item, index) in columns"
          :min-width="item.minWidth"
          :width="item.width"
          :key="index"
          :label="item.label"
          :prop="item.key"
          :align="item.align"
          :sortable="item.sortable"
          :fixed="item.fixed"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="item.key === 'No'">{{ scope.row.No }}</div>
            <div v-else-if="item.key === 'status'">
              <status :data="scope.row.status" />
            </div>
            <div v-else-if="item.key === 'toshop'">
              <span>{{ formatLabel(assignStatusList, scope.row.toShop) }}</span>
            </div>
            <div v-else-if="item.key === 'shopname'">
              <el-tooltip class="item" effect="dark" :content="scope.row.shopname" placement="top">
                <span>{{ scope.row.shopname.slice(0, 10) }}{{ scope.row.shopname.length > 10 ? '...' : '' }}</span>
              </el-tooltip>
            </div>
            <div v-else-if="item.key === 'operation'">
              <el-button @click="handleDelete(scope.row)" type="text" size="small">删除</el-button>
              <el-button @click="restSecond(scope.row)" type="text" size="small">设备管理</el-button>
            </div>
            <div v-else>{{ isEmpty(scope.row[item.key]) ? '--' : scope.row[item.key] }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flexend">
      <pagination
        :currentPage="pageOptions.pageNumber"
        :total="total"
        :pageSize="pageOptions.pageSize"
        @size="handleSizeChange"
        @current="handleCurrentChange"
      ></pagination>
    </div>
  </div>
</template>

<script>
import Pagination from 'src/components/Pagination'
import treemixin from 'src/mixins/tree'
import tmpColumn from 'src/mixins/templateColumn'
import { columns1 } from './common'
import { getProductList } from 'src/api/product'
import { storeHttp, formatLabel, generateTableData } from 'src/utils/common'
import { runningStatusList, assignStatusList, productTypeList } from 'src/utils/dict'
import { isEmpty } from 'src/utils/method'
import { solution } from 'src/api/product'
import Status from 'src/components/Status'

const pathList = [
  {
    path: '/productManage/retailTraffic',
    value: 0,
  },
  {
    path: '/productManage/security',
    value: 4,
  },
]

export default {
  components: {
    Pagination,
    Status,
  },
  mixins: [treemixin, tmpColumn],
  data() {
    const productType = pathList.find((item) => this.$route.path.startsWith(item.path))?.value

    return {
      columns: columns1,
      runningStatusList: [
        {
          label: '全部',
          value: null,
        },
      ].concat(runningStatusList),
      dataSource: [],
      tableData: [],

      prop: '',
      order: '',

      isFetching: true,
      pageOptions: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: 0,
      toshop: this.$route.query.toshop || '1',
      status: null,
      keyword: '',
      productType,
      assignStatusList,
    }
  },
  computed: {
    downloadurl() {
      const status = this.status !== null ? '&status=' + this.status : ''
      return (
        storeHttp +
        '/api/solution_package/getProductList/download?keyword=' +
        this.keyword +
        '&toshop=' +
        this.toshop +
        '&orgcode=' +
        this.orgcode +
        '&productType=' +
        this.productType +
        status
      )
    },
    type() {
      let type = null
      const currRouteName = this.$route.name
      if (currRouteName === 'retail-traffic-index') {
        type = 0
      } else if (currRouteName === 'security-index') {
        type = 4
      }
      return type
    },
  },
  watch: {
    tableData(val) {
      if (val.length && this.$route.query.from === '0') {
        this.setCurrent(val[0])
      }
    },
    pageOptions: {
      handler() {
        this.formatTableData()
      },
      deep: true,
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    formatLabel,
    isEmpty,
    init() {
      this.overviewInit()
      this.getTreeData().then(() => {
        this.$refs.tree?.setCurrentKey(this.orgcode)
      })
    },
    formatQueryData(data = {}) {
      return {
        ...data,
        productType: this.productType,
        toshop: parseInt(this.$route.query.toshop) || '1',
        status: this.status,
        orgcode: this.orgcode || 0,
        keyword: this.keyword,
      }
    },
    formatTableData() {
      this.tableData = generateTableData(this.dataSource, { order: this.order, prop: this.prop }, this.pageOptions)
    },
    generateIndex(arr = []) {
      let i = 1
      return arr.map((item) => {
        item.No = i++
        return item
      })
    },
    async fetchList() {
      this.isFetching = true
      const params = this.formatQueryData()

      try {
        const res = await getProductList(params)
        this.dataSource = this.generateIndex(res.data || [])
        this.total = res.data?.length || 0
        this.formatTableData()
      } finally {
        this.isFetching = false
      }
    },
    handleSelect(key) {
      //切换menu
      this.status = key
      this.pageOptions.pageNumber = 1
      this.overviewInit()
    },
    handleSizeChange(val) {
      this.pageOptions.pageSize = val
    },
    handleCurrentChange(val) {
      this.pageOptions.pageNumber = val
    },
    overviewInit() {
      //页面初始化
      this.fetchList()
    },
    async searchByKeywords() {
      await this.fetchList()
      this.pageOptions.pageNumber = 1
    },
    sortChange(column) {
      const { prop, order } = column
      this.prop = prop
      this.order = order
      this.formatTableData()
    },
    // 生成解决方案跳转
    toGenerateSolution() {
      const map = {
        0: 'retail-traffic-solution',
        4: 'security-solution',
      }
      this.$router.push({
        name: map[this.type],
        query: {
          type: this.type,
        },
      })
    },
    handleDelete(row) {
      const item = productTypeList.find((item) => item.value === this.type)
      this.$msgbox({
        message: `解决方案删除后设备将自动解除与门店的绑定关系，确认删除${row.shopName}门店下的${item.label}解决方案？`,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showCancelButton: true,
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            const { status } = await solution.deleteById({ productId: row.productId })
            if (status === 0) {
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.fetchList()
            }
            instance.confirmButtonLoading = false
          }
          done()
        },
      }).catch((error) => {})
    },
    restSecond(row) {
      let name = ''
      const currRouteName = this.$route.name
      if (currRouteName === 'retail-traffic-index') {
        name = 'retail-traffic-device'
      } else if (currRouteName === 'security-index') {
        name = 'security-device'
      }

      this.$router.push({
        name,
        query: {
          productId: row.productId,
          shopName: row.shopName,
          cname: row.cname,
          shopId: row.shopId,
          status: row.status,
        },
      })
    },

    // ----- 分割 ----
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row)
    },
  },
}
</script>

<style lang="less" scoped>
.operation {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  border-top: 1px solid #e6e6e6;
}
.el-button.el-button--default.primary {
  border: 1px solid #5c76cc;
  background-color: #5c76cc;
  color: #fff;
}
.menu .el-button + .el-button {
  margin-left: -4px;
}
.el-select .el-input {
  width: 318px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.searchInput {
  width: 350px;
}

.flexend {
  display: flex;
  justify-content: flex-end;
}

/* 设置自定义列 */
.el-checkbox + .el-checkbox {
  margin-left: 0;
}
.el-checkbox .el-checkbox {
  margin-right: 30px;
}
.ag-custom-column label {
  width: 33.33%;
  margin-bottom: 14px;
}
.ag-custom-column {
  width: 410px;
  position: absolute;
  top: 42px;
  right: 20px;
  background-color: #ffffff;
  border-radius: 2px;
  z-index: 99;
}
.bor {
  border: 1px solid #d2d2d2;
}
.fts {
  font-size: 12px;
}
.ag-layout-block {
  position: relative;
  padding: 15px;
}
.ag-layout-block.hori {
  padding-top: 0;
  padding-bottom: 0;
}
label {
  display: inline-block;
  line-height: 1.42857;
  font-style: normal;
}
.addDialog .el-input {
  width: 200px;
}

.el-input-group {
  width: 350px;
}

.el-input-group__append,
.el-input-group__prepend {
  padding: 0 4px;
}

.addDialog .shopBox span {
  top: 12px;
}

.addDialog .treeBox {
  left: 0;
}

.tree-disabled {
  cursor: not-allowed;
  color: #aca899;
}

.required .el-form-item__label:before {
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
}

.el-form-item.is-error .shop-name {
  border-color: #f56c6c;
}
.pswbox {
  position: relative;
  width: 200px;
}
.pswbox .el-input__inner {
  padding-right: 23px;
}
.password-icon {
  position: absolute;
  z-index: 100;
  top: 3px;
  right: 5px;
  font-size: 12px;
}
.upload > span {
  font-weight: bold;
  margin: 0 20px;
}
.el-dialog__footer {
  padding: 20px;
}
.assignDialog-footer > span {
  color: #5c76cc;
  cursor: pointer;
}
</style>